<div class="container-fluid">
    <div class="stretch-card">
        <div class="card">
            <div class="card-body">
                <button (click)="clickToAddCategory()" mat-raised-button color="primary" class="bg-dark" id="category">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
                    </svg>
                </button>
                <h4 class="card-title">Category</h4>
                <!-- <mat-form-field class="w-25" appearance="outline">
                    <mat-label class="text-dark">Search here</mat-label>
                    <input matInput (keyup)="dataFilter($event)" placeholder="Search..." />
                </mat-form-field> -->
                <div class="input-group w-25 ms-auto">
                    <input (keyup)="dataFilter($event)" type="text" class="form-control" placeholder="Search"
                        aria-label="search" aria-describedby="search">
                </div>

                <div>
                    <div class="">
                        <mat-table [dataSource]="categoryList" matSort>
                            <ng-container matColumnDef="image">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Image
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"><img
                                        src="https://api-sparkwallpaper.sparkleweb.co.in/content/Images/WallPaper/Category/{{element.image}}"
                                        alt="{{element.categoryName}}" height="30px" width="40px" class="rounded-1"> </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="categoryName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Category Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> <span
                                        [ngClass]="element.categoryName == Category_Name? 'active-title' : ''"> {{
                                        element.categoryName }} </span></mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="category" id="topic">
                                <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a (click)="clickToSubCategoryList(element)" class="btn btn-outline-dark" >
                                        Sub Category
                                    </a>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element"> <a
                                        routerLink="/home/categories/{{element.categoryID}}">
                                        <mat-icon color="primary" class="text-primary">edit</mat-icon>
                                    </a> <a class="deletebtn" (click)="deleteRow(element.categoryID)">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </a> </mat-cell>
                            </ng-container>
                            <!-- <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="/home/categories/{{element.categoryID}}">
                            <mat-icon color="primary">edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="deleteRow(element.categoryID)">
                            <mat-icon color="warn">delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </mat-cell>
            </ng-container> -->
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                            <div class="mat-row" *matNoDataRow>
                                <br>
                                <p class=" text-center text-muted" colspan="4">No record found.</p>
                            </div>
                        </mat-table>
                        <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
                            [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                            [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
                        </mat-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>