import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor(private _http:HttpClient) { }

postLoginList(data: any) {
  return this._http.post(`${environment.api_url}/api/account/login`, data);
}

}
