<div class="container">
  <div class="row">
    <h2 class="text-primary text-center my-5">Reset Password</h2>
    <div class="d-flex align-items-center justify-content-center w-100 ">
      <div class="py-3">
        <mat-card class="p-5">
          <form [formGroup]="resetPasswordForm" (ngSubmit)="postResetPassword()">
            <mat-form-field class="w-100 my-3" style="display: none;" appearance="outline">
              <input matInput formControlName="token" placeholder="Token" [(ngModel)]="token" autocomplete="on">
              <mat-error *ngIf="error('token', 'required')">Token required.</mat-error>
            </mat-form-field>
            <div class="example-container">
              <mat-form-field style="width: 300px; display: none;" appearance="outline">
                <mat-label class="text-dark">Enter your email</mat-label>
                <input matInput placeholder="pat@example.com" formControlName="email" required [(ngModel)]="email"
                  autocomplete="on">
                <mat-error *ngIf="error('email', 'required')">Please enter email id.</mat-error>
              </mat-form-field>
            </div>
            <div class="w-100 my-3 ">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="text-dark">Enter new password</mat-label>
                <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="newPassword" required
                  autocomplete="on">
                <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide2" type="button">
                  <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="error('newPassword', 'required')">Please enter new password.</mat-error>
                <mat-error *ngIf="error('newPassword', 'pattern')"> Password must be 8 character and Mark@12345
                </mat-error>
              </mat-form-field>
            </div>
            <div class=" w-100">
              <mat-form-field style="width: 300px;" appearance="outline">
                <mat-label class="text-dark">Confirm password</mat-label>
                <input matInput [type]="hide3 ? 'password' : 'text'" formControlName="confirmNewPssword" required
                  autocomplete="on">
                <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide3" type="button">
                  <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="error('confirmNewPssword', 'required')">Please enter confirm password</mat-error>
                <mat-error *ngIf="error('confirmNewPssword', 'MustMatch')">Password must match</mat-error>
              </mat-form-field>
            </div>
            <button mat-flat-button color="primary" class="my-2 w-25 mr-2" type="submit">Reset</button>
            <button mat-stroked-button color="primary" (click)="cancelBtn()" type="button"
              class="my-2 w-25">Cancel</button>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>