<div class="container-fluid">
    <div class="stretch-card">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Feedback</h4>
                <div class="input-group w-25 ms-auto">
                    <input  (keyup)="dataFilter($event.target)" type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="search">
                </div>
                <!-- <mat-form-field class="w-25" appearance="outline">
                    <mat-label class="text-dark">Search here</mat-label>
                    <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
                </mat-form-field> -->
                <div class="">
                    <mat-table [dataSource]="feedBackList" matSort id="table">
                        <ng-container matColumnDef="emailId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="rating">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Rating </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.rating}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ratingText">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> RatingText </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.ratingText}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                            <mat-cell *matCellDef="let element"> <i style="cursor: pointer;"
                                    (click)="deleteRow(element.id)">
                                    <mat-icon color="warn">delete</mat-icon>
                                </i></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        <div class="mat-row" *matNoDataRow>
                            <br>
                            <p class=" text-center text-muted" colspan="4">No record found.</p>
                        </div>
                    </mat-table>
                    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
                        [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                        [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- loading spinner -->
<div *ngIf="_loaderService.isLoading | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>