<div id="maindiv" class="container">
    <form id="form" [formGroup]="addSubCategoryForm" (ngSubmit)="onSubmit()">
        <h1 id="h1" class="text-center my-4 py-4">
            {{ editMode ? "Update" : "Add" }} Subcategory
        </h1>
        <mat-form-field appearance="fill" class="input-field" hidden>
            <mat-label class="text-dark">Category Id</mat-label>
            <input matInput formControlName="categoryID" />
            <mat-error *ngIf="hasError('categoryID', 'required')">Please enter category id.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="input-field" appearance="outline">
            <mat-label class="text-dark">Upcoming Date</mat-label>
            <input matInput formControlName="upcomingDate" placeholder="Enter Upcoming Date" (dateInput)="OnStopDate($event.value)" (click)=picker.open() [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- <mat-error *ngIf="hasError('upcomingDate', 'required')">Please enter upcoming date.
            </mat-error> -->
          </mat-form-field><br /><br />
        <mat-form-field appearance="fill" class="input-field" appearance="outline">
            <mat-label class="text-dark"> Image Name</mat-label>
            <input  matInput formControlName="subCategoryName" required placeholder="Enter Sub Category Name" />
            <mat-error *ngIf="hasError('subCategoryName', 'required')">Please enter sub category name.
            </mat-error>
            <mat-error *ngIf="hasError('subCategoryName', 'pattern')">Not allowed space.</mat-error>
        </mat-form-field><br /><br />
        <input type="file" (change)="onFileSelect($event)" formControlName="imageFile">
        <mat-error *ngIf="isSubmitted && hasError('imageFile', 'required')">Please choose any file.
        </mat-error>
        <br><br>
        <div class="row">
            <div class="col-md-6" *ngIf="previousImg">
                <p>Previous Image</p>
                <img src="https://api-sparkwallpaper.sparkleweb.co.in/content/Images/WallPaper/SubCategory/{{previousImg}}"
                    width="100px" height="100px" />
                <h2>{{ previousImg }}</h2>
            </div>
            <div class="col-md-6" *ngIf="selectedFileShow">
                <p>Current Image</p>
                <img [src]="selectedFileShow" width="100px" height="100px" />
                <h2 *ngIf="selectedFileShow">{{ selectedFileName }}</h2>
            </div>
        </div>
        <p>
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
        </p>
        <button mat-raised-button color="primary" type="submit" [disabled]="click">
            {{ editMode ? "Update" : "Submit" }}
        </button>
        <button mat-raised-button color="warn" class="mx-1" type="reset" (click)=resetForm()>
            Clear
        </button>
        <button mat-stroked-button color="primary" type="button" (click)="clicktobackList()">
            Cancel
        </button>
    </form>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>