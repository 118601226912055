import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private ROOT_URL = `${environment.api_url}`;

  constructor(private _http: HttpClient) {  }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 


  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });


  //
  getCategoryList(PageIndex: any, PageSize: any, Search: any) {
    return this._http.get(`${this.ROOT_URL}/api/Category/getCategorylist?PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}&devicetype=website`, { headers: this.headers });
  }

  postCategoryList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/Category/addcategory`, data, { headers: this.headers });
  }

  getByIdCategoryList(id: any) {
    return this._http.get(`${this.ROOT_URL}/api/Category/getCategorybyid?id=${id}&devicetype=website`, { headers: this.headers });
  }

  updateCategoryList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/Category/addcategory`, data, { headers: this.headers });
  }

  deleteCategoryList(id: any) {
    return this._http.delete(`${this.ROOT_URL}/api/Category/deleteCategory?id=${id}`, { headers: this.headers });
  }
}