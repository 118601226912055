import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LoginComponent } from './accout/login/login.component';
// import { UserComponent } from './user-list/user/user.component';
import { ListUserComponent } from './user-list/listUser/listUser.component';
import { ActivateGuard } from './authentication/activate.guard';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './accout/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './accout/change-password/change-password.component';
import { HomeComponent } from './HOME/home/home.component';
import { FeedbackComponent } from './FEEDBACK/feedback/feedback.component';
import { UpdateVersionListComponent } from './Update-Version/updateVersionList/updateVersionList.component';
import { UpdateVersionComponent } from './Update-Version/updateVersion/updateVersion.component';
// import { UpdateUserComponent } from './user-list/updateUser/updateUser.component';
import { AllCategoriesListComponent } from './WallPapers/categories/all-categories-list/all-categories-list.component';
import { AddCategoriesComponent } from './WallPapers/categories/add-categories/add-categories.component';
import { CategoryWiseImageListComponent } from './WallPapers/category-wise-images/category-wise-image-list/category-wise-image-list.component';
import { AddCategoryWiseImagesComponent } from './WallPapers/category-wise-images/add-category-wise-images/add-category-wise-images.component';
import { ResetPasswordComponent } from './accout/forgot-password/reset-password/reset-password.component';
import { SubCategoryListComponent } from './WallPapers/sub-category/sub-category-list/sub-category-list.component';
import { AddSubCategoryComponent } from './WallPapers/sub-category/add-sub-category/add-sub-category.component';
import { FavoriteListComponent } from './FAVORITE/favorite-list/favorite-list.component';
import { RecentComponent } from './RECENT/recent/recent.component';
import { LoginComponent } from './LOGIN/login/login.component';
import { UpdateUserComponent } from './UpdateUser/update-user/update-user.component';
import { AddUserComponent } from './addUser/add-user/add-user.component';

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "home", component: HeaderComponent, canActivate: [ActivateGuard], children: [
      { path: "dashboard", component: HomeComponent },
      { path: "AddUser", component: AddUserComponent },
      { path: "UserList", component: ListUserComponent },
      { path: "updateUser/:id", component: UpdateUserComponent },
      { path: "changepassword", component: ChangePasswordComponent },
      { path: 'favourite', component: FavoriteListComponent },
      { path: 'recent', component: RecentComponent },
      { path: "feedBack", component: FeedbackComponent },
      { path: "updateVersion", component: UpdateVersionListComponent },
      { path: "updatedVersion/:id", component: UpdateVersionComponent },
      { path: "categories", component: AllCategoriesListComponent },
      { path: "categories/:id", component: AddCategoriesComponent },
      { path: "categoriesAdd", component: AddCategoriesComponent },
      { path: "subcategory/:id", component: SubCategoryListComponent },
      { path: "subcategoryAdd", component: AddSubCategoryComponent },
      { path: "updatesubcategory/:id", component: AddSubCategoryComponent },
      { path: "categoryWiseImages/:id", component: CategoryWiseImageListComponent },
      { path: "categoryWiseImagesAdd", component: AddCategoryWiseImagesComponent },
      { path: "updateCategoryWiseImages/:id", component: AddCategoryWiseImagesComponent }
    ]
  },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  { path: "resetpassword", component: ResetPasswordComponent },
  { path: "signUp", component: AddUserComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }