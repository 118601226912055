import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting } from 'src/app/optimiseCode';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  public userData: any;
  public getToken: any;
  public msg = "";
  errorMsgHide = false;
  load: any;
  rememberMe:any = false;
  EMAIL: any = "";
  PASSWORD: any = "";
  isValid = false;

  constructor(private _fb: FormBuilder, private _loginService: LoginService, public _loaderService: LoaderService, public _cookiesService:CookieService) { }

  login = this._fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  ngOnInit() {
    if(this._cookiesService.get('remember') == 'true' && this._cookiesService.get('email') != "" && this._cookiesService.get('password') != ""){
      this.rememberMe = this._cookiesService.get('remember');
      this.EMAIL = this._cookiesService.get('email');
      this.PASSWORD = this._cookiesService.get('password');
    }
  }

  remember(e:any){
    this._cookiesService.set('remember', e.checked, 365);
    this._cookiesService.set('email', this.login.value.email, 365)
    this._cookiesService.set('password', this.login.value.password, 365)
  }

  onSubmit() {
    this.isValid = true;
    if (this.login.invalid) {
      return;
    }
    else {
      this.load = this._loaderService.isLoading;
      this._loginService.postLoginList(this.login.value).subscribe((result: any) => {
        localStorage.setItem('token', JSON.stringify(result.token));
        commonRouting('home/dashboard');
        this.load = false;
      },
        (error:any) => {
          if (error.error.status == "Error") {
            this.errorMsgHide = true;
            this.msg = error.error.message;
            this.load = false;
          }
          else{
            this.errorMsgHide = true;
            this.msg = "Server Error.";
            this.load = false;
          }
        });
    }
  }

  forgotPasswordNavigate() {
    commonRouting('forgotpassword');
  }

  signUp() {
    commonRouting('signUp');
  }
}
