<div id="maindiv" class="container">
    <form id="form" [formGroup]="addCategoryWiseImageForm" (ngSubmit)="onSubmit()">
        <h1 id="h1" class="text-center my-4 py-4">
            {{ editMode ? "Update" : "Add" }} Image
        </h1>
        <mat-form-field appearance="fill" class="input-field" hidden>
            <mat-label class="text-dark">Category Id</mat-label>
            <input matInput formControlName="subCategoryID" required />
            <mat-error *ngIf="hasError('subCategoryID', 'required')">Please enter category id.</mat-error>
        </mat-form-field>
        <br /><br />
        <mat-form-field appearance="fill" class="input-field" appearance="outline">
            <mat-label class="text-dark"> Image Name</mat-label>
            <input matInput formControlName="imageName" required placeholder="Enter Image Name" />
            <mat-error *ngIf="hasError('imageName', 'required')">Please enter image name.
            </mat-error>
            <mat-error *ngIf="hasError('imageName', 'pattern')">Not allowed space.</mat-error>
        </mat-form-field><br /><br />
        <input type="file" (change)="onFileSelect($event)" formControlName="imageFile">
        <mat-error *ngIf="isSubmitted && hasError('imageFile', 'required')">Please choose any file.
        </mat-error>
        <br><br>
        <div class="row">
            <div class="col-md-6" *ngIf="previousImg">
                <p>Previous Image</p>
                <img src="https://api-sparkwallpaper.sparkleweb.co.in/content/Images/WallPaper/ImageCategory/{{previousImg}}"
                    width="100px" height="100px" />
                <h2>{{ previousImg }}</h2>
            </div>
            <div class="col-md-6" *ngIf="selectedFileShow">
                <p>Current Image</p>
                <img [src]="selectedFileShow" width="100px" height="100px" />
                <h2 *ngIf="selectedFileShow">{{ selectedFileName }}</h2>
            </div>
        </div>
        <mat-form-field appearance="outline">
            <mat-label class="text-dark">Select Color</mat-label>
            <mat-select formControlName="color" required>
                <mat-option *ngFor="let colors of ColorList" [value]="colors.name">
                    {{colors.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('color', 'required')">Please select any color.
            </mat-error>
        </mat-form-field>
        <br><br>

        <p>
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
        </p>
        <br>
        <button mat-raised-button color="primary" type="submit" [disabled]="click">
            {{ editMode ? "Update" : "Submit" }}
        </button>
        <button mat-raised-button color="warn" class="mx-1" type="reset" (click)=resetForm()>
            Clear
        </button>
        <button mat-stroked-button color="primary" type="button" (click)="clicktobackList()">
            Cancel
        </button>
    </form>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>