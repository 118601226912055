<div id="div">
  <form [formGroup]="updateVersion" (ngSubmit)="onSubmit()">
    <h1 id="h1" class="text-center my-5 py-4">Update Version</h1>
    <mat-form-field appearance="fill" class="input-field">
      <mat-label>AppName</mat-label>
      <input matInput formControlName="appName">
    </mat-form-field><br><br>
    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Version</mat-label>
      <input matInput formControlName="version">
    </mat-form-field><br><br>
    <p>
      <mat-slide-toggle formControlName="status">Status</mat-slide-toggle>
    </p>
    <button mat-raised-button color="primary" type="submit" [disabled]="click">Submit</button>
    <button mat-raised-button color="warn" type="button" class="ml-3" (click)="clicktobackList()">Cancel</button>
  </form>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>