import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { SubCategoryService } from '../service/sub-category.service';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.css']
})
export class AddSubCategoryComponent implements OnInit {

  editMode = false;
  selectedFile: any;
  previousImg: any;
  selectedFileName: any;
  selectedFileShow: any;
  click: any;
  isSubmitted = false;
  load: any;

  public categoryId: any = sessionStorage.getItem('CategoryId');
  public CATEGORYID = this.categoryId;
  DATE: any = "";

  
  constructor(private _fb: FormBuilder, private _router: Router, private _subCategoryService: SubCategoryService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService, public datepipe: DatePipe) {
   }

  // UPCOMINGDATE :any = this.datepipe.transform(new Date(), 'MM-dd-YYYY');
  UPCOMINGDATE :any = this.datepipe.transform(new Date(), 'MM-dd-YYYY');

  OnStopDate(Value:any){
    this.UPCOMINGDATE = this.datepipe.transform(Value, 'MM-dd-YYYY'); 
    // this.addSubCategoryForm.controls.upcomingDate.setValue(update,{emitEvent: false});    // the value is not being set in yyyy-mm-dd but in mm-dd-yyyy
  }
  addSubCategoryForm: FormGroup = this._fb.group({
    categoryID: [this.CATEGORYID],
    // upcomingDate:[new Date(this.UPCOMINGDATE), [Validators.required]],
    upcomingDate:[''],
    subCategoryName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    image: [''],
    imageFile: ['', [Validators.required]],
    isActive: [true, [Validators.required]],
  });

  public hasError = (controlName: string, errorName: string) => {
    return this.addSubCategoryForm.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.id != undefined) {
      this.load = this._loaderService.isLoading;
      this._subCategoryService.getByIdSubCategoryList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
        this.UPCOMINGDATE = result.upcomingDate;
        this.load = false;
        this.editMode = true;
        this.previousImg = result.image;
        this.addSubCategoryForm = this._fb.group({
          categoryID: [this.CATEGORYID],
          subcategoryID: [result.subcategoryID],
          upcomingDate:[this.UPCOMINGDATE],
          // upcomingDate:[this.UPCOMINGDATE, [Validators.required]],
          subCategoryName: [result.subCategoryName, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
          image: [result.image],
          imageFile: [result.imageFile],
          isActive: [result.isActive, [Validators.required]],
        });
      });
    }
    else {
      this.load = false;
    }
  }

  onFileSelect(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.load = this._loaderService.isLoading;
    if (this.addSubCategoryForm.invalid) {
      this.load = false;
      return;
    }
    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      const formData = new FormData();
      formData.append('categoryID', this.addSubCategoryForm.value.categoryID);
      formData.append('upcomingDate', this.UPCOMINGDATE);
      formData.append('subCategoryName', this.addSubCategoryForm.value.subCategoryName);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      formData.append('isActive' ,this.addSubCategoryForm.value.isActive);
      this._subCategoryService.postSubCategoryList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              this._router.navigate(['/home/subcategory/', this.categoryId]);
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        } else if (result.status == "error") {
          onSweetAlertError(result);
        }
      })
    }

    else {
      const formData = new FormData();
      formData.append('subcategoryID', this._activatedRoute.snapshot.params.id);
      formData.append('categoryID', this.addSubCategoryForm.value.categoryID);
      formData.append('upcomingDate', this.UPCOMINGDATE);
      formData.append('subCategoryName', this.addSubCategoryForm.value.subCategoryName);
      formData.append('isActive' ,this.addSubCategoryForm.value.isActive);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName) {
        formData.append('image', this.selectedFileName);
      } else {
        formData.append('image', this.previousImg);
      }
      this._subCategoryService.updateSubCategoryList(formData).subscribe((result: any) => {
        this.load = false;
        if (result.status == "success") {
          this.click = !this.click;
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              this._router.navigate(['/home/subcategory/', this.categoryId]);
            }
          });
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }
  }

  clicktobackList() {
    this._router.navigate(['/home/subcategory/', this.categoryId]);
  }

  resetForm() {
    this.selectedFileShow = null;
  }

}
