<div class="container-fluid">
    <div class="stretch-card">
        <div class="card">
            <div class="card-body">
                <button (click)="clickToAddSubCategory()" mat-raised-button color="primary"
                    id="subcategory"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                        height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
                    </svg> </button>
                <h4 class="card-title"> <span [routerLink]="['/home/categories']" >Category</span> / <span class="text-primary">Sub Category</span> </h4>
                <p class="card-description"> {{categoryNameTitle}}</p>
                <div class="row">
                    <!-- <div class="col-lg-5 col-md-5 col-sm-4 mb-2">
                        <button [routerLink]="['/home/categories']" mat-raised-button color="primary">Back</button>
                    </div> -->
                    <!-- <div class="col-lg-3 col-md-2 col-sm-2">
                        <span id="span" class=" text-muted fs-4"></span>
                    </div> -->
                    <div class="col-lg-4 col-md-5 col-sm-6">
                    </div>
                </div>
                <div>
                    <div class="input-group w-25 ms-auto">
                        <input  (keyup)="dataFilter($event)" type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="search">
                    </div>
                   
                    <!-- <mat-form-field class="w-25" appearance="outline">
                        <mat-label class="text-dark">Search here</mat-label>
                        <input matInput (keyup)="dataFilter($event)" placeholder="Search..." />
                    </mat-form-field> -->
                    <div class="">
                        <mat-table [dataSource]="subCategoryList" matSort>
                            <ng-container matColumnDef="subCategoryName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Sub Category Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> <span
                                        [ngClass]="element.subCategoryName == Sub_Category_Name? 'active-title' : ''">
                                        {{ element.subCategoryName }} </span></mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="image">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Sub Category Image
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"><img
                                        src="https://api-sparkwallpaper.sparkleweb.co.in/content/Images/WallPaper/SubCategory/{{element.image}}"
                                        alt="{{element.subCategoryName}}" height="30px" width="40px" class="rounded-1"></mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="upcomingDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Upcoming Date </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{element.upcomingDate | date :'MM/dd/yyyy'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="imageList" id="topic">
                                <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a (click)="clickToImageList(element)"class="btn btn-outline-dark">
                                        Image List
                                    </a>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element"> <a
                                        routerLink="/home/updatesubcategory/{{element.subcategoryID}}">
                                        <mat-icon color="primary" class="text-primary">edit</mat-icon>
                                    </a> <a class="deletebtn" (click)="deleteRow(element.subcategoryID)">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </a> </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                            <div class="mat-row" *matNoDataRow>
                                <br>
                                <p class=" text-center text-muted" colspan="4">No record found.</p>
                            </div>
                        </mat-table>
                        <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
                            [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                            [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>