import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { SubCategoryService } from '../service/sub-category.service';

@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.css']
})
export class SubCategoryListComponent implements OnInit {
  displayedColumns: any[] = ['image','subCategoryName', 'upcomingDate', 'imageList', 'action'];
  subCategoryList: any = [];
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  categoryNameTitle = sessionStorage.getItem('CategoryTitle');
  categoryId = sessionStorage.getItem('CategoryId');
  FILTER = "";
  load:any;
  GETTITLE = sessionStorage.getItem('SubCategoryTitle');
  Sub_Category_Name: any;

  constructor(private _route: Router, public _loaderService: LoaderService, private _subCategoryService: SubCategoryService) { }

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngOnInit(): void {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('subCategoryPage') != null) {
      this.pageSize = sessionStorage.getItem('subCategoryPage');
      this.getAllSubCategoryList();
    }
    else {
      sessionStorage.setItem('subCategoryPage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('subCategoryPage1');
      this.getAllSubCategoryList();
    }
  }

  getAllSubCategoryList() {
    this._subCategoryService.getSubCategoryList(this.pageIndex, this.pageSize, this.FILTER, this.categoryId).subscribe((result: any) => {
      this.load = false;
      this.subCategoryList = result;
      this.subCategoryList.filter((x: any) => {
        if (x.subCategoryName == this.GETTITLE) {
          this.Sub_Category_Name = x.subCategoryName
        }
      });

      if (result.length > 0) {
        this.totalRecords = this.subCategoryList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      this.subCategoryList = new MatTableDataSource(this.subCategoryList);
      this.subCategoryList.sort = this.sort;
    });
  }

  clickToAddSubCategory() {
    commonRouting('home/subcategoryAdd');
  }

  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._subCategoryService.deleteSubCategoryList(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  dataFilter(event: any) {
    this.subCategoryList.filter = event.target.value.trim().toLowerCase();
    this.FILTER = this.subCategoryList.filter;
    this.getAllSubCategoryList();
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('subCategoryPage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('subCategoryPage', this.pageSize);
    this.getAllSubCategoryList();
  }

  clickToImageList(obj: any) {
    sessionStorage.setItem('SubCategoryTitle', obj.subCategoryName);
    sessionStorage.setItem('SubCategoryId', obj.subcategoryID);
    this._route.navigate(['/home/categoryWiseImages', obj.subcategoryID]);
  }
}
