import { Component, OnInit } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide3 = true;
  public msg = "";
  errorMsgHide = false;
  load: any;
  public getToken: any = localStorage.getItem('token');
  email:any = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];

  constructor(private fb: FormBuilder, private _myAccountService: MyAccountService, public _loaderService: LoaderService) { }

  changePasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    )]],
    confirmNewPssword: ['', [Validators.required]]
  },
    {
      validators: MustMatch('newPassword', 'confirmNewPssword')
    }
  )

  ngOnInit() {
    
  }

  public error = (control: string, error: string) => {
    return this.changePasswordForm.controls[control].hasError(error);
  }

  postChangePassword(data: any) {
    if (this.changePasswordForm.invalid) {
      return;
    }
    else {
      this.load = this._loaderService.isLoading;
      this._myAccountService.postChangePassword(data).subscribe((res: any) => {
        if (res.status == "Success") {
          onSweetAlertSuccess(res).then((res) => {
            if (res.isConfirmed) {
              location.reload();
              this.load = false;
            }
          });
        }
        else if(res.status == "Error"){
          onSweetAlertError(res).then((res) => {
            if (res.isConfirmed) {
              this.load = false;
            }
          });
        }
      })
    }
  }
}

export function MustMatch(controlName: any, matchingControlName: any) {
  {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      else if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}