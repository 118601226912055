import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  private ROOT_URL = `${environment.api_url}`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getFavoriteList(type:any, PageIndex: any, PageSize: any, Search: any,) {
    return this._http.get(`${this.ROOT_URL}/api/FavouriteDownload/getFavouriteDownloadlist?Type=${type}&devicetype=website&PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}`, { headers: this.headers });
  }

}
