import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { MyRecentService } from '../myRecent.service';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.css']
})
export class RecentComponent implements OnInit {

  displayedColumns: any[] = ['emailId', 'recentDateTime', 'imageName'];
  recentList: any;
  load:any;
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  FILTER: any = "";

  constructor(private _recentService: MyRecentService, public _loaderService: LoaderService, private _route:Router) { }

  ngOnInit() {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('recentPage') != null) {
      this.pageSize = sessionStorage.getItem('recentPage');
      this.RecentList();
    }
    else {
      sessionStorage.setItem('recentPage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('recentPage1');
      this.RecentList();
    }
    // this.USERID = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
    
  }

  RecentList(){
    this._recentService.getRecentList(this.pageIndex, this.pageSize, this.FILTER).subscribe((result: any) => {
      this.load = false;
      this.recentList = result;
      if (result.length > 0) {
        this.totalRecords = this.recentList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      
      this.recentList = new MatTableDataSource(this.recentList);
      this.recentList.sort = this.sort;
      // this.recentList.paginator = this.paginator;
    })
  }

  dataFilter(filterValue: any) {
    this.recentList.filter = filterValue.value.trim().toLowerCase();
    this.FILTER = this.recentList.filter;
    this.RecentList();
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('recentPage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('recentPage', this.pageSize);
    this.RecentList();
  }

}
