<div class="container-fluid">
    <div class="stretch-card">
        <div class="card">
            <div class="card-body">
                <button (click)="clickToAddCategoryWiseImages()" mat-raised-button color="primary"
                id="categoryWiseImage" class="bg-dark"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
            </svg></button>
            <h4 class="card-title"><span (click)="backToSubCategory()">Sub Category</span> / <span class="text-primary">Image</span></h4>
            <p class="card-description"> {{subcategoryNameTitle}}</p>

                <div class="row">
                    <!-- <div class="col-lg-5 col-md-5 col-sm-4 mb-2">
                        <button (click)="backToSubCategory()" mat-raised-button color="primary">Back</button>
                    </div> -->
                    <!-- <div class="col-lg-3 col-md-2 col-sm-2">
                        <span id="span" class=" text-muted fs-4">{{subcategoryNameTitle}}</span>
                    </div> -->
                    <div class="col-lg-4 col-md-5 col-sm-6">
                    </div>
                    <div class="input-group w-25 ms-auto">
                        <input  (keyup)="dataFilter($event)" type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="search">
                    </div>
                </div>
                <div>
                    <!-- <mat-form-field class="w-25" appearance="outline">
                        <mat-label class="text-dark">Search here</mat-label>
                        <input matInput (keyup)="dataFilter($event)" placeholder="Search..." />
                    </mat-form-field> -->
                    <div class="">
                        <mat-table [dataSource]="categoryWiseImageList" matSort>
                            <ng-container matColumnDef="imageName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Image Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.imageName }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="image">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Image
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"><img
                                        src="https://api-sparkwallpaper.sparkleweb.co.in/content/Images/WallPaper/ImageCategory/{{element.image}}"
                                        alt="{{element.imageName}}" height="30px" width="40px" class="rounded-1"></mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="color">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Color </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{element.color}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element"> <a
                                        routerLink="/home/updateCategoryWiseImages/{{element.imageID}}">
                                        <mat-icon color="primary" class="text-primary">edit</mat-icon>
                                    </a> <a class="deletebtn" (click)="deleteRow(element.imageID)">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </a> </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                            <div class="mat-row" *matNoDataRow>
                                <br>
                                <p class=" text-center text-muted" colspan="4">No record found.</p>
                            </div>
                        </mat-table>
                        <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
                            [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                            [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>