import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { FavoriteService } from '../favorite.service';

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.css']
})
export class FavoriteListComponent implements OnInit {
  displayedColumns: any[] = ['emailId', 'type', 'downloadImage'];
  favoriteList: any;
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  FILTER = "";
  load:any;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  TYPE="Favourite";
  listTypes = [
    {type:"Favourite"},
    {type:"Download"}
  ];

  constructor(private _favoriteService: FavoriteService, public _loaderService: LoaderService, private _route:Router) { }

  ngOnInit() {
    this.load = this._loaderService.isLoading;
    // this.USERID = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
    if (sessionStorage.getItem('favouritePage') != null) {
      this.pageSize = sessionStorage.getItem('favouritePage');
      this.favoriteAndDownloadList();
    }
    else {
      sessionStorage.setItem('favouritePage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('favouritePage1');
      this.favoriteAndDownloadList();
    }
    
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('favouritePage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('favouritePage', this.pageSize);
    this.favoriteAndDownloadList();
  }

  favoriteAndDownloadList(){
    this._favoriteService.getFavoriteList(this.TYPE, this.pageIndex, this.pageSize, this.FILTER).subscribe((result: any) => {
      this.load = false;
      this.favoriteList = result;
      if (result.length > 0) {
        this.totalRecords = this.favoriteList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      this.favoriteList = new MatTableDataSource(this.favoriteList);
      this.favoriteList.sort = this.sort;
      // this.favoriteList.paginator = this.paginator;
    })
  }

  changeType(val:any){
    this.TYPE = val;
    this.load = this._loaderService.isLoading;
    this.favoriteAndDownloadList();
  }

  dataFilter(filterValue: any) {
    this.favoriteList.filter = filterValue.value.trim().toLowerCase();
    this.FILTER = this.favoriteList.filter;
    this.favoriteAndDownloadList();
  }

}
