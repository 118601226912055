<h1 class="text-center  my-5 py-4">Update Version</h1>
<div class="mat-elevation-z8">
    <mat-table [dataSource]="updateVersionList1" matSort id="table">
        <ng-container matColumnDef="appName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> App Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.appName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.version}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
            <mat-cell *matCellDef="let element"> <a routerLink="/home/updatedVersion/{{element.id}}"><mat-icon color="primary">edit</mat-icon></a></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<!-- loading spinner -->
<div *ngIf="_loaderService.isLoading | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>