<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
      <div class="row flex-grow">
        <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3">
            <div class="brand-logo">
              <img src="assets/images/SparkWall_icon.jpg" alt="logo">
            </div>
            <h4>Welcome back!</h4>
            <h6 class="font-weight-light">Happy to see you again!</h6>
            <form class="pt-3" [formGroup]="login" id="form" novalidate> 
              <div class="form-group">
                <label for="exampleInputEmail">Username</label>
                <div class="input-group">
                  <div class="input-group-prepend bg-transparent">
                    <span class="input-group-text bg-transparent border-right-0">
                      <i class="mdi mdi-account-outline text-primary"></i>
                    </span>
                  </div>
                  <input type="email" class="form-control form-control-lg border-left-0" formControlName="email" [(ngModel)]="EMAIL" required autocomplete="on" id="exampleInputEmail" placeholder="Username">
                </div>
                <mat-error *ngIf="isValid && error('email', 'required')">Email is required.</mat-error>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword">Password</label>
                <div class="input-group">
                  <div class="input-group-prepend bg-transparent">
                    <span class="input-group-text bg-transparent border-right-0">
                      <i class="mdi mdi-lock-outline text-primary"></i>
                    </span>
                  </div>
                  <input type="password" formControlName="password" [(ngModel)]="PASSWORD" required autocomplete="on" class="form-control form-control-lg border-left-0" id="exampleInputPassword" placeholder="Password">                        
                </div>
                <mat-error *ngIf="isValid && error('password', 'required')">Password is required.</mat-error>
              </div>
              <div class="my-2 d-flex justify-content-between align-items-center">
                <div class="form-check">
                  <label class="form-check-label text-muted">
                    <input type="checkbox" class="form-check-input" [checked]="rememberMe" (change)="remember($event)">
                    Keep me signed in
                  </label>
                </div>
                <a  class="auth-link text-black" (click)="forgotPasswordNavigate()">Forgot password?</a>
              </div>
              <div class="my-3">
                <button  type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="onSubmit()">LOGIN</button>
              </div>
              <!-- <div class="mb-2 d-flex">
                <button type="button" class="btn btn-facebook auth-form-btn flex-grow me-1">
                  <i class="mdi mdi-facebook me-2"></i>Facebook
                </button>
                <button type="button" class="btn btn-google auth-form-btn flex-grow ms-1">
                  <i class="mdi mdi-google me-2"></i>Google
                </button>
              </div> -->
              <!-- <div class="text-center mt-4 font-weight-light">
                Don't have an account? <a href="register-2.html" class="text-primary">Create</a>
              </div> -->
            </form>
            <div class="alert alert-danger" role="alert" *ngIf="errorMsgHide">
              <span *ngIf="errorMsgHide">{{msg}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 login-half-bg d-flex flex-row">
          
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>

<!-- <div *ngIf="load | async" class="loading-bar">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div> -->