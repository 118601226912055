import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { MyFeedbackService } from '../myFeedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  displayedColumns: any[] = ['emailId', 'rating', 'ratingText', 'description', 'action'];
  feedBackList: any;
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  FILTER: any = "";

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  load: any;

  constructor(private _feedBackService: MyFeedbackService, public _loaderService: LoaderService, private _route:Router) { }

  ngOnInit() {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('feedBackPage') != null) {
      this.pageSize = sessionStorage.getItem('feedBackPage');
      this.getFeedBackList();
    }
    else {
      sessionStorage.setItem('feedBackPage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('feedBackPage1');
      this.getFeedBackList();
    }

  }

  getFeedBackList() {
    this._feedBackService.getFeedBackList(this.pageIndex, this.pageSize, this.FILTER).subscribe((result: any) => {
      this.load = false;
      this.feedBackList = result;
      if (result.length > 0) {
        this.totalRecords = this.feedBackList[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      this.feedBackList = new MatTableDataSource(this.feedBackList);
      this.feedBackList.sort = this.sort;
    })
  }

  dataFilter(filterValue: any) {
    this.feedBackList.filter = filterValue.value.trim().toLowerCase();
    this.FILTER = this.feedBackList.filter;
    this.getFeedBackList();
  }

  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._feedBackService.deleteFeedBackList(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('feedBackPage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('feedBackPage', this.pageSize);
    this.getFeedBackList();
  }

}
