import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  load: any;

  constructor(private _fb: FormBuilder, private _myAccountService: MyAccountService, private router: Router, public _loaderService: LoaderService) { }

  forgotPasswordForm = this._fb.group({
    email: ['', [Validators.required]],
  });

  public error = (control: string, error: string) => {
    return this.forgotPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
  }

  postForgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    else {
      this.load = this._loaderService.isLoading
      this._myAccountService.postForgotPassword(this.forgotPasswordForm.value.email).subscribe((res: any) => {
        if (res.status == "success") {
          onSweetAlertSuccess(res);
          this.router.navigate(['resetpassword']);
          this.load = false;
        }
        else if (res.status == "error") {
          onSweetAlertError(res);
          this.load = false;
        }
      })
    }
  }

  cancelBtn() {
    commonRouting('login');
  }
}