import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { MyUserService } from '../myUser.service';


@Component({
  selector: 'app-listUser',
  templateUrl: './listUser.component.html',
  styleUrls: ['./listUser.component.css']
})



export class ListUserComponent implements OnInit {

  displayedColumns: any[] = ['firstname', 'lastname', 'email', 'phoneNumber', 'action'];
  dataSource: any;
  appLanguageList: string[] = ['English', 'Hindi', 'Gujarati'];
  totalRecords = 0;
  pageSize: any = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  load:any;
  FILTER = "";

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  constructor(private _userService: MyUserService, private _router: Router, public _loaderService: LoaderService) { }

  handlePageEvent(event: PageEvent) {
    sessionStorage.removeItem('userPage1');
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    sessionStorage.setItem('userPage', this.pageSize);
    this.getUserList();
  }

  dataFilter(event: any) {
    this.dataSource.filter = event.target.value.trim().toLowerCase();
    this.FILTER =  this.dataSource.filter;
    this.getUserList();
  }

  ngOnInit() {
    this.load = this._loaderService.isLoading;
    if (sessionStorage.getItem('userPage') != null) {
      this.pageSize = sessionStorage.getItem('userPage');
      this.getUserList();
    }
    else {
      sessionStorage.setItem('userPage1', this.pageSize);
      this.pageSize = sessionStorage.getItem('userPage1');
      this.getUserList();
    }
    
  }

  getUserList() {
    this._userService.getUserList(this.pageSize, this.pageIndex, this.FILTER).subscribe((result:any) => {
      this.dataSource = result;
      if (result.length > 0) {
        this.totalRecords = this.dataSource[0].totalRecords;
      }
      else {
        this.totalRecords = 0;
      }
      
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
    });
  }

  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._userService.deleteUserList(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  clickToAddUser() {
    commonRouting('home/AddUser');
  }
}