<div class="container-scroller">

<!-- partial:partials/_horizontal-navbar.html -->
<div class="horizontal-menu">
<nav class="navbar top-navbar col-lg-12 col-12 p-0">
<div class="container-fluid">
<div class="navbar-menu-wrapper d-flex align-items-center justify-content-between">
<div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center ms-auto">
    <a class="navbar-brand brand-logo"><img src="assets/images/SparkWall_icon.jpg" alt="logo"/></a>
    <a class="navbar-brand brand-logo text-dark">SparkWall </a>
</div>
<ul class="navbar-nav navbar-nav-right">
    <li class="nav-item nav-profile dropdown">
      <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
        <span class="nav-profile-name">{{firstname}} {{lastname}}</span>
        <span class="online-status"></span>
        <img src="assets/images/SparkWall_icon.jpg" alt="profile"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
          <a class="dropdown-item" [routerLink]="['changepassword']" >
            <i class="mdi mdi-settings text-primary"></i>
            Change Password
          </a>
          <a class="dropdown-item" (click)="onLogOut()">
            <i class="mdi mdi-logout text-primary"></i>
            Logout
          </a>
      </div>
    </li>
</ul>
<button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
  <span class="mdi mdi-menu"></span>
</button>
</div>
</div>
</nav>
<nav class="bottom-navbar">
<div class="container">
<ul class="nav page-navigation">
  <li class="nav-item">
    <a class="nav-link"routerLink="dashboard" routerLinkActive="active">
      <i class="mdi mdi-file-document-box menu-icon"></i>
      <span class="menu-title">Dashboard</span>
    </a>
  </li>
  <li class="nav-item">
      <a routerLink="UserList" routerLinkActive="active" class="nav-link">
        <i class="mdi mdi-account-multiple menu-icon"></i>
        <span class="menu-title">Users</span>
        <i class="menu-arrow"></i>
      </a>
  </li>
  <li class="nav-item">
      <a [routerLink]="['categories']" routerLinkActive="active" class="nav-link">
        <i class="mdi mdi-chart-areaspline menu-icon"></i> 
        <span class="menu-title">Categories</span>
        <i class="menu-arrow"></i>
      </a>
  </li>
  <li class="nav-item">
      <a [routerLink]="['recent']" routerLinkActive="active" class="nav-link">
        <i class="mdi mdi-finance menu-icon"></i>
        <span class="menu-title">Recent</span>
        <i class="menu-arrow"></i>
      </a>
  </li>
  <li class="nav-item">
      <a [routerLink]="['favourite']" routerLinkActive="active" class="nav-link">
        <i class="mdi mdi-grid menu-icon"></i>
        <span class="menu-title">Favorite</span>
        <i class="menu-arrow"></i>
      </a>
  </li>
  <li class="nav-item">
      <a  class="nav-link" [routerLink]="['feedBack']" routerLinkActive="active">
        <i class="mdi mdi-emoticon menu-icon"></i>
        <span class="menu-title">Feedback</span>
        <i class="menu-arrow"></i>
      </a>
  </li>
  <li class="nav-item">
      <a [routerLink]="['updateVersion']" routerLinkActive="active" class="nav-link">
        <i class="mdi mdi-codepen menu-icon"></i>
        <span class="menu-title">Update Version</span>
        <i class="menu-arrow"></i>
      </a>
      <!-- <div class="submenu">
          <ul class="submenu-item">
              <li class="nav-item"><a class="nav-link" href="pages/samples/login.html">Login</a></li>
              <li class="nav-item"><a class="nav-link" href="pages/samples/login-2.html">Login 2</a></li>
              <li class="nav-item"><a class="nav-link" href="pages/samples/register.html">Register</a></li>
              <li class="nav-item"><a class="nav-link" href="pages/samples/register-2.html">Register 2</a></li>
              <li class="nav-item"><a class="nav-link" href="pages/samples/lock-screen.html">Lockscreen</a></li>
          </ul>
      </div> -->
  </li>
  <!-- <li class="nav-item">
      <a href="docs/documentation.html" class="nav-link">
        <i class="mdi mdi-file-document-box-outline menu-icon"></i>
        <span class="menu-title">Documentation</span></a>
  </li> -->
</ul>
</div>
</nav>
</div>
<!-- partial -->
<div class="container-fluid page-body-wrapper">
<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->

    <!-- partial -->
</div>
<!-- main-panel ends -->
</div>
<!-- page-body-wrapper ends -->
</div>