import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyUpdateUserService {
  public url = `${environment.api_url}/api/account`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getList(id: any) {
    return this._http.get(`${this.url + "/getuserbyguid?GUID="}${id}`, { headers: this.headers });
  }

  updateUserList(data: any) {
    return this._http.put(`${this.url + "/updateuserdetails?devicetype=website"}`, data, { headers: this.headers });
  }
}
