import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { commonRouting } from '../optimiseCode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logoPath = '/src/assets/images/Logo.png';
  public getToken: any;
  public firstname: any;
  public lastname:any;

  constructor() { }

  ngOnInit(): void {
    this.getToken = localStorage.getItem('token')
    this.firstname = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    this.lastname = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        commonRouting('login');
      }
    });
  }
}