<h1 class="text-center  my-4 py-4">Favourite And Download List</h1>
<mat-form-field class="w-25 mr-3" appearance="outline">
    <mat-label class="text-dark">Search here</mat-label>
    <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
</mat-form-field>
<mat-form-field appearance="fill">
    <!-- <mat-label>Favorite and Download</mat-label> -->
    <mat-select [(ngModel)]="TYPE" (ngModelChange)="changeType($event)">
      <mat-option *ngFor="let listType of listTypes" [value]="listType.type">
        {{listType.type}}
      </mat-option>
    </mat-select>
  </mat-form-field>
<div class="mat-elevation-z8">
    <mat-table [dataSource]="favoriteList" matSort id="table">
        <ng-container matColumnDef="emailId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="imageID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Image ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.imageID}} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="downloadImage">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Download Image </mat-header-cell>
            <mat-cell *matCellDef="let element"> <img src="{{element.downloadImage}}" height="20px" width="30px"> </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="isFavourite">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Favorite </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.isFavourite}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isDownload">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Download </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.isDownload}} </mat-cell>
      </ng-container> -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <div class="mat-row" *matNoDataRow>
            <br>
            <p class=" text-center text-muted" colspan="4">No record found.</p>
        </div>
    </mat-table>
    <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
        </mat-paginator>
</div>
<!-- loading spinner -->
<div *ngIf="load | async" class="loading-bar">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>