import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyUpdateUserService } from '../my-update-user.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {

  constructor(private _fb: FormBuilder, private _myUpdateUserService: MyUpdateUserService, private _activatedRoute: ActivatedRoute, private _router: Router, public _loaderService: LoaderService) { }

  userlist:any = this._fb.group({
    email: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    // password: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }

  ngOnInit() {
    this.getUserList();
  }

  getUserList() {
    this._myUpdateUserService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      console.log(result)
      this.userlist = this._fb.group({
        id: [result.id],
        email: [result.email],
        firstname: [result.firstname, [Validators.required, Validators.pattern('^[a-zA-Zs]*$')]],
        lastname: [result.lastname, [Validators.required, Validators.pattern('^[a-zA-Zs]*$')]],
        // username: [result.username, [Validators.required]],
        phoneNumber: [result.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        // password: [result.passwordHash, Validators.required],
      }, {validators: this.customValidator()});
      // this.userlist.controls['email'].disable();
      // this.userlist.get('email').disable();
      // this.userlist.controls['password'].disable();
    });
  }

 customValidator(){
    this.userlist.get('email').disable({emitEvent: false});
  }

  onSubmit() {
    if (this.userlist.invalid) {
      return;
    }
    else {
      // this.userlist.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      const formData = new FormData();
      formData.append('id', this._activatedRoute.snapshot.params.id);
      formData.append('firstname', this.userlist.value.firstname);
      formData.append('lastname', this.userlist.value.lastname);
      formData.append('email', this.userlist.value.email);
      formData.append('phoneNumber' ,this.userlist.value.phoneNumber);
      this._myUpdateUserService.updateUserList(formData).subscribe((result: any) => {
        if (result.status == "success") {
          onSweetAlertSuccess(result).then((isCheckOk) => {
            if (isCheckOk.isConfirmed) {
              commonRouting('home/UserList');
            }
          });
        } else if (result.status == "error") {
          onSweetAlertError(result);
        }
      });
    }
  }

  omit_special_char_name(event: any) {
    var k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123)
  }
}
