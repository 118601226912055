import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryWiseImagesService {

  private ROOT_URL = `${environment.api_url}`;

  constructor(private _http: HttpClient) { }

  token: any = localStorage.getItem('token')
  tokenParse: any = JSON.parse(this.token); 

  headers: any = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenParse}`
  });

  getCategoryWiseImageList(PageIndex: any, PageSize: any, Search: any, id: any) {
    return this._http.get(`${this.ROOT_URL}/api/ImageCategory/getImagelistbySubCategoryId?SubCategoryID=${id}&PageIndex=${PageIndex}&PageSize=${PageSize}&Search=${Search}&devicetype=website`, { headers: this.headers });
  }

  postCategoryWiseImageList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/ImageCategory/addImageCategory`, data, { headers: this.headers })
  }

  getByIdCategoryWiseImageList(id: any) {
    return this._http.get(`${this.ROOT_URL}/api/ImageCategory/getImageCategorybyid?id=${id}&devicetype=website`, { headers: this.headers });
  }

  updateCategoryWiseImageList(data: any) {
    return this._http.post(`${this.ROOT_URL}/api/ImageCategory/addImageCategory`, data, { headers: this.headers });
  }

  deleteCategoryWiseImageList(id: any) {
    return this._http.delete(`${this.ROOT_URL}/api/ImageCategory/deleteImageCategory?id=${id}`, { headers: this.headers });
  }
}