import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // JSON.parse(window.atob(this.getToken.split('.')[1]))

  public getToken: any;
  public firstname: any;
  public lastname:any;

  constructor() { }

  ngOnInit(): void {
    this.getToken = localStorage.getItem('token')
    this.firstname = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    this.lastname = JSON.parse(window.atob(this.getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
  }

}