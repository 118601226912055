import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ListUserComponent } from './user-list/listUser/listUser.component';
import { ForgotPasswordComponent } from './accout/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './accout/forgot-password/reset-password/reset-password.component';
import { ChangePasswordComponent } from './accout/change-password/change-password.component';
import { HomeComponent } from './HOME/home/home.component';
import { UpdateVersionComponent } from './Update-Version/updateVersion/updateVersion.component';
import { UpdateVersionListComponent } from './Update-Version/updateVersionList/updateVersionList.component';
import { MyUpdateVersionService } from './Update-Version/myUpdateVersion.service';
import { FeedbackComponent } from './FEEDBACK/feedback/feedback.component';
import { InterceptorService } from './loader/interceptor.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AllCategoriesListComponent } from './WallPapers/categories/all-categories-list/all-categories-list.component';
import { AddCategoriesComponent } from './WallPapers/categories/add-categories/add-categories.component';
import { CategoryWiseImageListComponent } from './WallPapers/category-wise-images/category-wise-image-list/category-wise-image-list.component';
import { AddCategoryWiseImagesComponent } from './WallPapers/category-wise-images/add-category-wise-images/add-category-wise-images.component';
import { CategoriesService } from './WallPapers/categories/service/categories.service';
import { CategoryWiseImagesService } from './WallPapers/category-wise-images/service/category-wise-images.service';
import { SubCategoryListComponent } from './WallPapers/sub-category/sub-category-list/sub-category-list.component';
import { AddSubCategoryComponent } from './WallPapers/sub-category/add-sub-category/add-sub-category.component';
import { SubCategoryService } from './WallPapers/sub-category/service/sub-category.service';
import { FavoriteListComponent } from './FAVORITE/favorite-list/favorite-list.component';
import { FavoriteService } from './FAVORITE/favorite.service';
import { RecentComponent } from './RECENT/recent/recent.component';
import { MyRecentService } from './RECENT/myRecent.service';
import { MyAccountService } from './accout/myAccount.service';
import { LoginService } from './LOGIN/login.service';
import { LoginComponent } from './LOGIN/login/login.component';
import { MyUserService } from './user-list/myUser.service';
import { MyUpdateUserService } from './UpdateUser/my-update-user.service';
import { UpdateUserComponent } from './UpdateUser/update-user/update-user.component';
import { AddUserComponent } from './addUser/add-user/add-user.component';
import { MyAddUserService } from './addUser/my-add-user.service';

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    AddUserComponent,
    ListUserComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    HomeComponent,
    FavoriteListComponent,
    RecentComponent,
    FeedbackComponent,
    UpdateVersionComponent,
    UpdateVersionListComponent,
    AllCategoriesListComponent,
    AddCategoriesComponent,
    CategoryWiseImageListComponent,
    AddCategoryWiseImagesComponent,
    SubCategoryListComponent,
    AddSubCategoryComponent,
    UpdateUserComponent,
    AddUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({
      warnOnNgModelWithFormControl: 'never'
    }),
    FormsModule,
    CommonModule,
    EditorModule,
    MatTooltipModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }, MyUserService, MyAddUserService, MyUpdateUserService, MyUpdateVersionService,MyAccountService, LoginService, CategoriesService, CategoryWiseImagesService, SubCategoryService, FavoriteService, MyRecentService,
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}